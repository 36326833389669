<template>
  <UiPopup
    :model-value="modelValue"
    title="Role can't be changed"
    description="This user is included to a team. You can set a new role after excluding this user from a team."
    primary-button-text="Open teams"
    secondary-button-text="Cancel"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="$emit('input'), emits('update:modelValue', false)"
  >
  </UiPopup>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
}

withDefaults(defineProps<Props>(), {
  modelValue: false,
})
</script>

<style scoped></style>
